import * as React from "react";
import {FunctionComponent} from "react";
import MainLayout from "../layouts/MainLayout";
import LinkPage from "../components/LinkPage/LinkPage";

const IndexPage: FunctionComponent = () => (
  <MainLayout>
      <LinkPage/>
  </MainLayout>
);

export default IndexPage;