const Links = [
  "https://www.velo.com/pl/pl/poznaj-velo",
  "https://www.velo.com/pl/pl/poznaj-velo",
  "https://www.velo.com/pl/pl/poznaj-velo",
  "https://www.velo.com/pl/pl/poznaj-velo",
  "https://www.velo.com/pl/pl/poznaj-velo",
  "https://www.velo.com/pl/pl/poznaj-velo",
];

export default Links;
