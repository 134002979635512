import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import * as Styled from "./LinkPage.styled";
import Button from "../Button/Button";
import { useLocation, navigate } from "@reach/router";
import Links from "./Links";

const LinkPage: FunctionComponent = () => {
  const location = useLocation();
  const [number, setNumber] = useState<number>(1);
  const [counter, setCounter] = useState<number>(5);

  useEffect(() => {
    if (!location.hash) {
      setNumber(1);
    } else {
      const nb = parseInt(location.hash.substring(1));
      if (nb) {
        setNumber(nb);
      } else {
        setNumber(1);
      }
    }
  }, [location]);

  useEffect(() => {
    if (counter === 0) {
      navigate(Links[number - 1]).then();
      return;
    }
    setTimeout(() => {
      setCounter((prev) => prev - 1);
    }, 1000);
  }, [counter]);

  return (
    <Styled.Content number={number}>
      <Styled.TextWrapper>
        <Styled.Title>Nie mówię. Działam.</Styled.Title>
        <Styled.Subtitle>Jestem gotowy na kickstart.</Styled.Subtitle>
      </Styled.TextWrapper>
      <Styled.CounterWrapper>
        <Styled.Text>Wchodzę za</Styled.Text>
        <Styled.Counter>00:0{counter}</Styled.Counter>
      </Styled.CounterWrapper>
      <Styled.CtaWrapper>
        <Button text={`Wchodzę teraz`} link={Links[number - 1]} bigger />
      </Styled.CtaWrapper>
    </Styled.Content>
  );
};

export default LinkPage;
