import styled, { css } from "styled-components";
import Variables from "../../styles/Variables";
import { mediaDown } from "../../styles/Mixins";
import DesktopBg1 from "../../images/lips-1-desktop.jpg";
import DesktopBg2 from "../../images/lips-2-desktop.jpg";
import DesktopBg3 from "../../images/lips-3-desktop.jpg";
import DesktopBg4 from "../../images/lips-4-desktop.jpg";
import DesktopBg5 from "../../images/lips-5-desktop.jpg";
import DesktopBg6 from "../../images/lips-6-desktop.jpg";

import MobileBg1 from "../../images/lips-1-mobile.jpg";
import MobileBg2 from "../../images/lips-2-mobile.jpg";
import MobileBg3 from "../../images/lips-3-mobile.jpg";
import MobileBg4 from "../../images/lips-4-mobile.jpg";
import MobileBg5 from "../../images/lips-5-mobile.jpg";
import MobileBg6 from "../../images/lips-6-mobile.jpg";

type Props = {
  number: number;
};

export const Content = styled.header<Props>`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: url("${DesktopBg1}") center no-repeat;
  ${(props) =>
    props.number === 2 &&
    css`
      background: url("${DesktopBg2}") center no-repeat;
    `};
  ${(props) =>
    props.number === 3 &&
    css`
      background: url("${DesktopBg3}") center no-repeat;
    `};
  ${(props) =>
    props.number === 4 &&
    css`
      background: url("${DesktopBg4}") center no-repeat;
    `};
  ${(props) =>
    props.number === 5 &&
    css`
      background: url("${DesktopBg5}") center no-repeat;
    `};
  ${(props) =>
    props.number === 6 &&
    css`
      background: url("${DesktopBg6}") center no-repeat;
    `};
  background-size: cover;

  ${mediaDown(
    Variables.breakpoints.md,
    css`
      background: url("${MobileBg1}") center no-repeat;
      ${(p: Props) =>
        p.number === 2 &&
        css`
          background: url("${MobileBg2}") center no-repeat;
        `};
      ${(p: Props) =>
        p.number === 3 &&
        css`
          background: url("${MobileBg3}") center no-repeat;
        `};
      ${(p: Props) =>
        p.number === 4 &&
        css`
          background: url("${MobileBg4}") center no-repeat;
        `};
      ${(p: Props) =>
        p.number === 5 &&
        css`
          background: url("${MobileBg5}") center no-repeat;
        `};
      ${(p: Props) =>
        p.number === 6 &&
        css`
          background: url("${MobileBg6}") center no-repeat;
        `};
      background-size: cover;
    `
  )};
`;

export const TextWrapper = styled.div``;

const TitleBase = css`
  color: ${Variables.colors.white};
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  font-weight: ${Variables.fontWeight.bold};
  text-shadow: 0 0 40px rgba(31, 29, 34, 0.5);
`;

export const Title = styled.h1`
  ${TitleBase};
  color: ${Variables.colors.teal};
  font-size: 10rem;
  margin-bottom: 3rem;
  ${mediaDown(
    Variables.breakpoints.md,
    css`
      font-size: 8rem;
      margin-bottom: 2rem;
    `
  )};
  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      font-size: 6.4rem;
    `
  )};
`;

export const Subtitle = styled.h2`
  ${TitleBase};
  font-size: 4rem;
  ${mediaDown(
    Variables.breakpoints.md,
    css`
      font-size: 4rem;
    `
  )};
  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      font-size: 2.6rem;
    `
  )};
`;

export const CtaWrapper = styled.div`
  margin-top: 12rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  a {
    margin-bottom: 6rem;
  }
`;

export const CounterWrapper = styled.div`
  color: ${Variables.colors.white};
  text-align: center;
  font-weight: ${Variables.fontWeight.bold};
  margin-top: 8rem;
`;

export const Text = styled.p`
  font-size: 2.4rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

export const Counter = styled.p`
  font-size: 8rem;
  line-height: 1;
`;
